@import url('https://fonts.googleapis.com/css2?family=WindSong:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=WindSong:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap');
* {
  text-decoration: none !important;
}
main {
  min-height: 70vh;
}
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
img {
  pointer-events: none
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
h3 {
  padding: 1rem 0;
}
body{
  background-color: #729efe21;
}
.navbar-light .navbar-brand {
  color: white !important;
}
.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
    border-radius: 8px;
    margin: 2px;
    display: flex;
    align-items: center;
}
.whatsappbutton{
  border-radius: 8px;
  background-color: #26C541;
  color: white;
  border: 0px;
  padding: 10px;
  font-weight: 600;
}
.category{
    color: rgb(255, 254, 254);
    padding: 1px 5px;
    padding-left: 0px;
    padding-right: 10px;
    border-radius: 20px;
    margin: 3px;
}
.category:hover{
  background-color: white;
  color: #000;
  transition: .5s all ease-in;
}
.category-active{
  padding: 1px 5px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 20px;
  margin: 3px;
  background-color: white;
  color: #000;
}
.category::selection{
  background-color: white;
  color: #000;
}
.category:hover svg{
  color: black !important;
  transition: .5s all ease-in;
}
.nav-bar{
  /* background: linear-gradient(180deg, #F7D6FF 0%, #005686 100%), linear-gradient(180deg, #FFFFFF 0%, #060046 100%), linear-gradient(130deg, #00FFA3 0%, #1A003C 100%), linear-gradient(307deg, #FF0000 0%, #3300C6 100%), radial-gradient(50% 72% at 50% 50%, #004584 0%, #00FFB2 100%), radial-gradient(100% 140% at 100% 0%, #5ED500 0%, #2200AA 100%);
    background-blend-mode: soft-light, overlay, difference, difference, color-burn, normal; */
      background-color: #000000;
      width: 100%;
      padding: 10px;
}
.navbar-light .navbar-nav .nav-link.active{
  color: white;
  background-color: rgba(255, 255, 255, 0.467);
}
.navbar-toggler{
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
}
.contact{
  background-color: #ffffff72;
  padding: 4px 6px;
  border-radius: 4px;
}
.artgallery{
      width: 100%;
      min-height: 100vh;
}
.logo{
  display: flex;
  width: 60px;
  height: 50px;
}
.search{ 
  border-top-right-radius: 10px !important; 
  border-bottom-right-radius: 10px !important;
  background-image: linear-gradient(to right, #FF8B22, #FE1EF3);
}
.comments{
  display:'flex';
  flex-direction:'row';
  margin-top:'20px';
}
.logo{
  font-family: 'Gloria Hallelujah', cursive;
  text-transform: none;
  font-size: 40px;
}
.button{
  color: white !important;
  border-radius: 10px !important;
  padding: 10px !important;
  background-color: #000000 !important;
  border: 1px solid white !important;
}
.button:hover{
  transform:scale(1.05);
  box-shadow: 5px 5px 5px;
  transition: all .5s ease-in-out;
}
.inputsearch{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.rating span {
  margin: 0.1rem;
}
.list-group-item{
  border: 0px !important;
}
.card-length{
  height: inherit;
  display: flex;
}
.card-body{
  flex: none !important;
}
.card-img-center{
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardd{
  border: #898787b2 1px solid;
  padding: 10px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}
.cardd:hover{
  box-shadow: 5px 5px 5px;
  transition: all .5s ease-in-out;
}
.latestproducts{
  font-size: 50px;
  text-transform: none;
  font-family: 'WindSong', cursive;
}
.headings-aboutme{
  font-size: 28px;
  font-family: 'Manrope', sans-serif;
  margin: 20px 0px;
  color: white;
}
.footer{
    background-color: #000000;
    width: 100%;
  color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
}
.footer p{
  display: flex;
  font-size: 10px;
  cursor: pointer;
}
.art-exibition{
  display: flex;
  margin: 10vh 0;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(30, 0, 40, 0.6)), url('../src/assets/artexibition.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 0;
}
.headings{
  font-size: 28px;
  margin: 20px 0px;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #55595c;
}

.landing-view {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(10, 15, 20, 0.4)), url('../src/assets/home1.jpeg');
  background-size: cover;
}

.landing-view h1 {
  font-size: 2.8vw;
}

.landing-view h6 {}

.landing-view>div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.writing {
  display: flex;
  flex-direction: column;
  color: white;
}

.writing p {
  color: #ffeee4;
  font-size: 20px;
  width: 30vw;
}

.writing h1 {
  margin: 0px;
  align-items: flex-start;
  color: #ffeee4;
}

.writing span {
  color: #F6D51F;
  font-weight: bolder;
  font-size: 30px;
}

.landing-view-image {
  z-index: 10;
}

.aboutme {
  margin: 10vh 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(30, 0, 40, 0.6)), url('../src/assets/aboutmebackground.JPG');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vw 0;
}

.about-me-heading{
  color: white;
}

.aboutme .profile {
  display: flex;
  justify-content: center;
  width: 50vw;
}

.profile img {
  height: fit-content;
  width: 25vw;
}

.about-text-section {
  padding: 20px;
  width: 50vw;
  color: #ffeee4;
}

.about-text-section p {
  font-size: 1.4vw;
}

.wig {
  display: flex;
  padding: 0;
}

.wig li {
  padding: 10px;
  list-style: none;
}

.wig li a {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  margin: 0 0px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 1px black;
  z-index: 1;
}

.wig li a .fa {
  position: relative;
  color: #d3d1d1;
  transition: 0.5s;
  z-index: 3;
  font-size: 28px;
}

.wig li a:hover .fa {
  color: #fff;
  transform: rotateY(360deg);
  font-size: 35px;
}

.wig li:nth-child(1) a:hover .fa {
  color: #00acee;
}

.wig li:nth-child(2) a:hover .fa {
  color: #c13584;
}

.wig li:nth-child(3) a:hover .fa {
  color: #4267b2;
}

.wig li:nth-child(4) a:hover .fa {
  color: white;
}

.wig li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #f00; */
  transition: 0.5s;
  z-index: 2;
}
.outerbar{
  margin: 15px;
  height: 5px;
  border: #000000 1px solid;
}
.innerbar{
  color: #000;
  background-color: #000;
  height: 5px;
}
.art-options{
  background-color: #000;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0px 20px 20px;
  padding: 0px 10px !important;
}
.termandconditions li,p{
 font-size: large;
}
.exibition-card{
  display: flex;
  background-color: #33255E;
  padding: 20px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.exibition-card h4{
  margin-top: 10px;
  font-weight: 500;
}
.exibition-card h5,p,h4{
  color: white;
}
.exibition-card div{
  color: grey;
}
.MuiFormControl-marginNormal{
  width: -webkit-fill-available;
}
.E-button{
  padding: 8px;
  font-size: small;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid #fff;
    box-shadow: 1px 2px 5px hsl(0deg 0% 100% / 50%);
}
.css-6hp17o-MuiList-root-MuiMenu-list{
  display: grid !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list *{
  padding: 10px !important;
}
.profile img{
  border-radius: 60% 40% 40% 20%;
}
@media (min-width: 992px){
.carousel .container, .container-lg, .container-md, .container-sm {
    max-width: max-content;
    padding-left: 0px;
    padding-right: 0px;
}
}

@media only screen and (max-width: 760px) {
  .css-15v22id-MuiAccordionDetails-root{
    padding: 0px !important;
  }
  .profile img{
    height: 430px;
  }
  .exibition-card {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .exibition-card p{
    font-size: small !important;
  }
  .navbar-light .navbar-nav .nav-link.active {
      color: white;
      background-color: rgba(255, 255, 255, 0.467);
      padding-left: 10px;
    }
  .art-exibition{
    display: flex;
    flex-direction: column-reverse;
  }
  .writing p {
      color: #ffeee4;
      font-size: 20px;
      width: 90vw;
    }
  .landing-view {
    padding-top: 100px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .landing-view>.writing {
    display: flex;
    justify-content: unset;
    width: 100vw;
    min-height: 100vh;
  }

  .landing-view h1 {
    font-size: 8vw;
  }

  .aboutme {
    flex-direction: column;
    padding: 10vh 50px;
  }

  .aboutme .profile img {
    width: 100%;
  }

  .aboutme .profile {
    width: auto;
  }

  .about-text-section {
    width: 100vw;
    padding: 5vh 0;
  }

  .about-text-section h1 {
    padding: 0 20px;
  }

  .about-text-section p {
    font-size: 2vh;
    padding: 20px;
  }

  .about-text-section button {
    margin: 0 20px;
  }
    .footer {
      flex-direction: column;
    }
}